.app-main-products {
    background: url("../images/bgMain/bg2-1920.png") no-repeat;
    position: relative;
    margin-bottom: 35px;
    background-size: cover;
    border-radius: 10px;
}

.app-main-products .blueBtn {
    position: absolute;
    left: 74px;
    bottom: 67px;
    border-radius: 4px;
    cursor: pointer;
    width: 236px;
    height: 58px;
}

.app-main-products-wrapper {
    padding-left: 74px;
    padding-bottom: 178px;
}

.app-main-products-wrapper input {
    display: none;
}

.app-main-products-wrapper label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-main-products-radio {
    max-width: 458px;
    width: 25%;
    height: 59px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    position: relative;
    border-bottom: 1px solid rgba(229, 231, 229, 1);
    font-size: 21px;
}

.app-main-products-radio, .app-main-products-radio>* {
    cursor: pointer;
}

.p-1 .app-main-products-radio {
    border-top: 1px solid rgba(229, 231, 229, 1);
}

.app-main-products-radio img {
    margin-right: 10px;
}

.active label span{
    font-size: 21px;
    font-weight: 500;
}

.active::after {
    content: '';
    width: 212px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -1px;
    background: rgba(76, 172, 233, 1);
}

.bounce::after, .bounce img {
    animation: bounce 0.5s ease 1;
}

.previousBounce img {
    animation: prevBounce 0.5s ease 1;
}

@keyframes bounce {
    10% { transform:translateY(0%); }
    20% { transform:translateY(7px); }
    30% { transform:translateY(0%); }
    40% { transform:translateY(-6px); }
    50% { transform:translateY(0%); }
    60% { transform:translateY(5px); }
    70% { transform:translateY(0%); }
    80% { transform:translateY(-4px); }
    90% { transform:translateY(0); }
    100% { transform:translateY(3px); }
}

@keyframes prevBounce {
10% { transform:translateY(0%); }
20% { transform:translateY(7px); }
30% { transform:translateY(0%); }
40% { transform:translateY(-6px); }
50% { transform:translateY(0%); }
60% { transform:translateY(5px); }
70% { transform:translateY(0%); }
80% { transform:translateY(-4px); }
90% { transform:translateY(0); }
100% { transform:translateY(3px); }
}

.app-main-products-context {
    height: 705px;
    max-width: 1021px;
    width: 65%;
    position: absolute;
    top: 176px;
    right: 0px;
    margin-right: 4.3%;
    border-radius: 3px;
    background-color: rgba(235, 236, 235, 1);
    overflow: hidden;
}

.app-main-products-context>div:first-child {
    position: absolute;
    top: 40px;
    left: 53px;
}

.app-main-products-context>div:first-child p{
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}

.app-main-products-context>div:first-child p:last-of-type{
    color: rgba(76, 172, 233, 1);
}

.app-main-product-block-active,
.app-main-product-block-inActive {
    display: flex;
}

@media all and (max-width: 1400px) {
    .active label span {
        font-size: 17px;
        font-weight: 500;
    }
}

@media all and (max-width: 1200px) {
    .app-main-products-context {
        position: relative;
        height: 701px;
        top: 0px;
        width: 100%;
        max-width: 92%;
    }

    .product-main-slider {
        max-width: 100%;
    }

    .app-main-products .blueBtn {
        position: relative;
        left: 0px;
        bottom: -5px;
        width: 280px;
    }

    .app-main-product-block-active,
    .app-main-product-block-inActive {
        flex-direction: column;
        position: relative;
    }

    .app-main-product-block-active {
        height: 742px;
    }
    .inActive label span{
        font-size: 18px;
        font-weight: 500;
    }

    .app-main-products {
        background: url("../images/bgMain/bg2-mobile.png") no-repeat;
        background-size: cover;
        margin-bottom: 30px;
    }

    .app-main-products-wrapper {
        padding-left: 22px;
        padding-top: 23px;
        padding-bottom: 37px;
    }

    .app-main-products-radio {
        padding-left: 0px;
        width: 92%;
        max-width: 92%;
    }

    .active label span{
        font-size: 21px;
    }
}
@media all and (max-width: 1024px) {
    .app-main-product-block-active {
        max-height: 542px;
        height: auto;
    }

    .app-main-products-context>div:first-child {
        top: 10px;
        left: 13px;
    }

    .app-main-products-context {
        height: 375px;
    }

    .product-simple-slide {
        height: 375px;
    }
}
