.app-contactModal,
.app-main-vacancies-accordeon-item-sendModal {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 120;
}

.app-contactModal-content {
    background: #FFFFFF;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 602px;
    width: 100%;
    top: 217px;
    padding: 62px 65px 36px 65px;
    border-radius: 4px;
}

.app-contactModal-content-close-button {
    position: absolute;
    top: -80px;
    right: -110px;
    color: white;
    font-size: 25px;
    font-weight: 100;
    cursor: pointer;
}

.app-contactModal-content h2 {
    font-size: 32px;
    font-weight: 500;
    z-index: 10;
    position: relative;
    text-align: center;
}

.app-contactModal-content-subTitle {
    font-size: 12px;
    display: block;
    min-height: 22px;
}

.app-contactModal-input-header {
    font-size: 12px;
    font-weight: 400;
    padding: 10px 0px;
}

.app-contactModal-content img {
    position: absolute;
    left: -20px;
    top: 3px;
}

.app-contactModal-input-wrapper {
    display: flex;
    justify-content: space-between;
}

.app-contactModal-input-item {
    width: 49%;
}

.app-contactModal-input,
.app-contactModal .ant-picker {
    height: 51px;
    border-radius: 3px;
    border: 1px solid rgba(209, 220, 229, 1);
    background-color: rgba(243, 245, 248, 1);
    width: 100%;
    padding: 0px 10px;
}

.app-contactModal-input:focus {
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: none;
    padding: 10px;
}

.app-contactModal .ant-picker {
    width: 100%;
}

.app-contactModal-policy-block {
    display: flex;
    align-items: center;
}

.app-contactModal-input-label {
    width: 26px;
    height: 26px;
    marginRight: '10px';
}

.app-contactModal-policy-block {
    margin-top: 24px;
    margin-bottom: 28px;
}

.app-contactModal-policy-block label {
    position: relative;
    margin-right: 5px;
}

.app-contactModal-policy-block label:before {
    content: '';
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 2px;
    border: 1px solid rgba(209, 220, 229, 1);
    background: rgba(243, 245, 248, 1);
    width: 25px;
    height: 25px;
}

.app-contactModal-policy-block input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 9px;
    width: 8px;
    height: 18px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.app-contactModal-content button.blueBtn {
    width: 100%;
    height: 58px;
    border-radius: 2px;
}

.app-contactModal-date-wrapper {
    display: flex;
}

.app-contactModal-date-wrapper input {
    display: none;
}

.app-contactModal-date-wrapper label {
    padding-right: 5px;
}

.app-contactModal-date-wrapper input:checked + label > span {
    color: rgba(76, 172, 233, 1);
}

.animated-notification img {
    height: 30px;
    margin-right: 10px;
}

.rnc__notification-item>div {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 2px;
}

.MuiTextField-root {
    width: 100%;
}

@media all and (max-width: 768px) {
    .app-contactModal-content label {
        line-height: 15px;
    }

    .app-contactModal-content h2 {
        font-size: 24px;
        text-align: left;
        margin-left: 20px;
        margin-bottom: 12px;
        line-height: 20px;
    }

    .app-contactModal-input-header {
        padding: 8px 0px 8px;
    }

    span.app-contactModal-input-header, a.app-contactModal-input-header {
        padding: 0px;
    }

    .app-contactModal-content {
        max-width: 329px;
        top: 19px;
        padding: 31px 21px 27px 22px;
    }

    .app-contactModal-content img {
        left: -5px;
    }

    .app-contactModal-input-wrapper {
        flex-direction: column;
    }

    .app-contactModal-input-item {
        width: 100%;
    }

    .app-contactModal-date-wrapper {
        display: block;
        margin-top: 10px;
    }

    .app-contactModal-date-wrapper div:first-of-type {
        float: left;
    }

    .app-contactModal-policy-block {
        display: inline-flex;
        flex-wrap: wrap;
    }

    .app-contactModal-policy-block {
        margin-top: 11px;
        margin-bottom: 14px;
    }

    .app-contactModal-content-close-button {
        top: -20px;
        right: -10px;
        color: black;
    }
}