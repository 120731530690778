@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

* {
    font-family: Ubuntu;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: rgba(46, 55, 60, 1);
    border: none;
    box-sizing: border-box;
    font-weight: 400;
}

body {
    background: rgba(235, 236, 235, 1);
}

button {
    cursor: pointer;
}

.app-main-header, .app-main-products, .app-main-services, .app-main-contacts {
    scroll-margin-top: 80px;
}

.app {
    padding-top: 10px;
    max-width: 1648px;
    margin: 0 auto;
    width: 93%;
}

.app-header {
    font-weight: 500;
    position: fixed;
    padding-top: 16px;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 100;
    left: 0;
    right: 0;
    top: 0px;
}

.app-header.solid {
    background-color: white;
}

.app-header-wrapper {
    display: flex;
    max-width: 1615px;
    width: 90%;
    margin: 0 auto;
    padding-left: 1%;
    padding-bottom: 17px;
}

.app-header img {
    height: 47px;
}

.app-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3%;
    width: 100%;
    cursor: pointer;
}

.app-header-button {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(221, 224, 234, 1);
    display: flex;
    align-items: center;
    padding: 10px 32px;
    justify-content: center;
    border-radius: 2px;
}

.app-header-button img {
    height: 26px;
}

.app-header-container-menu {
    display: flex;
    max-width: 430px;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
}

.app-header a {
    text-decoration: none;
    color: rgba(46, 55, 60, 1);
    display: block;
}

.app-header-container-menu-divider {
    height: 12px;
    width: 1px;
    background: rgba(46, 55, 60, 1);
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
}

.app-header-container-contacts {
    max-width: 400px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    align-items: center;
}

.app-header-container-phone img {
    height: 14px;
    margin-right: 5px;
}

.page-header {
    font-size: 34px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.06em;
    padding: 119px 0px 44px 71px;
}

.app-main-footer {
    display: flex;
    margin-top: 50px;
    padding-left: 100px;
    justify-content: space-between;
}

.app-main-footer-logo {
    display: flex;
}

.app-main-footer-logo img {
    height: 33px;
    opacity: 0.4;
}

.app-main-footer-logo div {
    width: 250px;
    padding-left: 35px;
    color: rgba(115, 115, 115, 0.6);
    font-size: 13px;
    line-height: 15px;
}

.app-main-footer-description p, .app-main-footer-description span {
    color: rgba(115, 115, 115, 0.6);
    font-size: 13px;
    line-height: 15px;
}

.app-main-footer-description {
    text-align: right;
    padding-bottom: 326px;
}

.app-main-footer-description img {
    height: 15px;
}

.blueBtn {
    border: 1px solid rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(76, 172, 233, 1);
}

.blueBtn span {
    width: 229px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.page-description {
    max-width: 679px;
    padding-top: 94px;
    padding-right: 70px;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
}

@media all and (max-width: 1700px) {
    .app-main-footer-description {
        padding-right: 10px;
    }
}

@media all and (max-width: 1500px) {
    .app {
        width: 90%;
    }
}

@media all and (max-width: 1200px) {
    .app {
        padding-top: 20px;
    }

    .app-header {
        padding-top: 16px;
    }

    .page-description {
        padding: 0px 70px;
    }

    .header-wrapper {
        flex-direction: column;
    }

    .app-header-container-phone span {
        font-size: 16px;
    }

    .app-header {
        padding-top: 25px;
    }

    .app-header-wrapper {
        justify-content: space-between;
        width: 85%;
        align-items: center;
        padding-left: 0px;
        padding-bottom: 5px;
    }

    .app-header-container-menu-divider {
        margin-top: 0px;
    }

    .app-header-container-menu {
        margin-left: 0px;
        width: 60%;
    }

    .app-header-container-menu>div {
        font-size: 12px;
    }

    .app-header-container-contacts {
        width: 35%;
        padding-top: 0px;
        align-items: center;
    }

    .app-header-button span {
        font-size: 14px;
        font-weight: 500;
    }

    .app-header a {
        margin-top: 0px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
    }

    .app-header-container {
        margin-left: 0px;
        justify-content: space-between;
        width: 74%;
        align-items: center;
    }

    .app-header img {
        height: 30px;
    }

    .app-header-button img {
        margin-left: 9px;
        height: 20px;
    }
}

@media all and (max-width: 1023px) {
    .page-header {
        padding: 79px 0px 44px 71px;
    }

    .app-header-button {
        margin: 0px;
        width: 280px;
        border-radius: 2px;
    }

    .app-header-button {
        padding: 0px 10px;
        width: auto;
        height: 38px;
    }

    .app-header-button span {
        font-size: 12px;
    }

    .app-header a {
        font-size: 12px;
    }

    .app {
        padding-top: 10px;
    }

    .app-header-wrapper {
        width: 85%;
    }

    .app-header-container-phone img {
        height: 20px;
    }
}

@media all and (max-width: 768px) {
    * {
        font-size: 15px;
        line-height: 20px;
    }

    .app-main-header, .app-main-products, .app-main-services, .app-main-contacts {
        scroll-margin-top: 20px;
    }

    .app {
        max-width: 327px;
    }

    .page-description {
        padding: 0px;
    }

    .app-header {
        padding: 14px 0px;
    }

    .app-header-wrapper {
        justify-content: space-between;
        max-width: 327px;
        align-items: center;
        padding-left: 0px;
    }

    .page-header {
        font-size: 24px;
        line-height: 18px;
        text-align: left;
        padding: 61px 0px 14px 21px;
    }

    .app-header-container {
        justify-content: flex-end;
    }

    .page-description {
        padding: 0px 22px;
        line-height: 16px;
        font-size: 14px;
    }

    .app-main-footer {
        margin-top: 30px;
        padding-left: 5px;
        margin-bottom: 30px;
    }

    .app-main-footer-logo div {
        display: flex;
        justify-content: flex-end;
    }

}
