.slide-wrapper {
    display: flex;
    position: relative;
    right: 0;
    transition: 0.5s ease;
}

.main-slider button{
    position: absolute;
    height: 42px;
    width: 41px;
    top: 138px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide-window {
    display: flex;
    overflow: hidden;
    border-radius: 8px;
}

.simple-slide {
    width: 350px;
    height: 472px;
    background: url('../images/carousel/carouselBg.png');
}

.main-slider {
    display: flex;
    position: absolute;
    right: 6%;
    top: 328px;
}

.slide-left {
    left: 38px;
    border-radius: 2px;
}

.slide-right {
    left: 87px;
    border-radius: 2px;
}

.inActiveArr {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 1);
}

.activeArr {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(76, 172, 233, 1);
}

.carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    color: rgba(58, 74, 84, 1);
}

.carousel h1 {
    font-size: 30px;
    font-weight: 500;
}

.carousel span {
    font-size: 16px;
    font-weight: 400;
}

.carousel img {
    margin: 0 auto;
}

.carousel-description {
    padding: 35px 0px 0px 36px;
}

@media all and (max-width: 1600px) {
    .main-slider {
        right: 3%;
    }
}

@media all and (max-width: 1400px) {
    .simple-slide {
        width: 212px;
        height: 286px;
        background: url('../images/carousel/carouselBg.png');
    }

    .main-slider {
        right: 5%;
        top: 208px;
    }

    .carousel h1 {
        font-size: 21px;
    }

    .carousel span {
        font-size: 13px;
        width: 53%;
        display: block;
        padding-top: 4px;
    }

    .carousel span {
        font-size: 13px;
        width: 93%;
        display: block;
        padding-top: 4px;
    }

    .carousel-description {
        padding: 15px 0px 0px 15px;
    }

    .main-slider button {
        top: 92px;
    }

    .slide-left {
        left: 15px;
    }

    .slide-right {
        left: 67px;
    }
}
