.app-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 35px auto 0px;
    position: relative;
    height: 720px;
    width: 93%;
}

.app-main-monstrApp {
    background: url('../images/bgMain/bg4-1920.png') no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 35px;
    min-height: 1013px;
    position: relative;
}

.app-wrapper::before {
    content: "";
    position: absolute;
    top: -45px;
    left: -5px;
    width: 115px;
    height: 130px;
    border-top: 5px solid white;
    border-left: 5px solid white;
}

.app-wrapper::after {
    content: "";
    position: absolute;
    bottom: 40px;
    left: -5px;
    width: 115px;
    height: 130px;
    border-bottom: 5px solid white;
    border-left: 5px solid white;
}

.app-wrapper > div::after {
    content: "";
    position: absolute;
    bottom: 40px;
    right: 0px;
    width: 115px;
    height: 130px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
}

.app-wrapper > div::before {
    content: "";
    position: absolute;
    top: -45px;
    right: 0px;
    width: 115px;
    height: 130px;
    border-top: 5px solid white;
    border-right: 5px solid white;
}

.app-item-title {
    font-size: 21px;
}

.app-item {
    width: 367px;
    position: relative;
    margin-right: 10px;
}

.app-item > img:first-of-type {
    height: 489px;
}

.app-item-description-wrapper {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 390px;
    right: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(115, 115, 115, 0.4);
    backdrop-filter: blur(4px);
    cursor: pointer;
}

img.app-item-description {
    height: 20px;
}

.app-item-title {
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 16px;
}

.app-arrows {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 146px;
    cursor: pointer;
}

.app-arrows > div {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}


.app-footer {
    display: flex;
    padding-bottom: 50px;
    justify-content: flex-end;
    padding-right: 63px;
}

.app-footer div {
    font-size: 16px;
    line-height: 18px;
    max-width: 562px;
    padding-right: 60px;
    display: flex;
    align-items: center;
}

.app-footer button {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: rgba(76, 172, 233, 1);
    border-radius: 4px;
    cursor: pointer;
    width: 236px;
    height: 58px;
}

.animatedContent {
    position: absolute;
    z-index: 1000;
    display: none;
    width: 88%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 9px;
}

.app-simple-slide:not(:last-of-type) .animatedContent,
.app-simple-slide:nth-of-type(4) .animatedContent > div div {
    background: rgba(150, 150, 150, 0.3);
    backdrop-filter: blur(10px);
    box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.05);
    padding: 17px;
}

.animatedContent * {
    color: white;
    font-size: 12px;
}

.app-simple-slide:first-of-type .animatedContent img {
    height: 47px;
}

.app-simple-slide:nth-of-type(2) .animatedContent img {
    width: 100%;
    margin-top: 40px;
}

.app-simple-slide:nth-of-type(3) .animatedContent,
.app-simple-slide:nth-of-type(4) .animatedContent > div div:first-of-type{
    border-radius: 9px 9px 0px 9px;
}

.app-simple-slide:nth-of-type(4) .animatedContent > div div:last-of-type{
    border-radius: 9px 9px 9px 0px;
}

.animatedContent span {
    padding-left: 5px;
}

.visible {
    animation: slide 3s 1 ease-out alternate;
    top: 30%;
    display: block;
}

@keyframes slide {
    0% {
        transform: translate3d(-50%, -500px, 0);
    }

    20% {
        transform: translate3d(-50%, 60%, 0);
    }

    40% {
        transform: translate3d(-50%, -60%, 0);
    }

    60% {
        transform: translate3d(-50%, 30%, 0);
    }

    80% {
        transform: translate3d(-50%, -30%, 0);
    }
}

@media all and (max-width: 1200px) {
    .app-footer {
        padding-right: 25px;
        padding-left: 25px;
    }

    .app-main-monstrApp {
        background: url('../images/bgMain/bg4-mobile.png') no-repeat;
        background-size: cover;
    }
}

@media all and (max-width: 768px) {
    .app-main-monstrApp {
        padding: 0px 21px;
        margin-bottom: 0px;
        min-height: 863px;
    }

    .app-slide-window {
        height: 440px;
    }

    .app-main-slider {
        width: 95%;
        margin-top: 19px;
    }

    .app-wrapper {
        height: 565px;
        width: 100%;
    }

    .app-wrapper::before {
        content: "";
        position: absolute;
        top: 1.6%;
        left: 0px;
        width: 89px;
        height: 75px;
        border-top: 3px solid white;
        border-left: 3px solid white;
    }

    .app-wrapper > div::before {
        content: "";
        position: absolute;
        top: 1.6%;
        right: 0px;
        width: 89px;
        height: 75px;
        border-top: 3px solid white;
        border-right: 3px solid white;
    }

    .app-item {
        width: 270px;
        margin-right: 0px;
    }

    .app-item > img:first-of-type {
        height: 359px;
    }

    .app-item-title {
        font-size: 18px;
    }

    img.app-item-description {
        height: 14px;
    }

    .app-item-description-wrapper {
        width: 55px;
        height: 55px;
        top: 290px;
        left: 200px;
    }

    .app-wrapper > div::after {
        top: 80.65%;
        right: 0px;
        width: 89px;
        height: 75px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
    }

    .app-wrapper::after {
        top: 80.65%;
        left: 0px;
        width: 89px;
        height: 75px;
        border-bottom: 3px solid white;
        border-left: 3px solid white;
    }

    .app-footer {
        padding-bottom: 50px;
        padding-right: 0px;
        padding-left: 0px;
        align-items: center;
        flex-direction: column;
    }

    .app-footer div {
        padding-right: 0px;
        padding-bottom: 16px;
    }
}
