.app-main-slider {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
    max-width: 1493px;
    overflow: hidden;
}

.app-slide-window {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.app-slide-wrapper {
    display: flex;
    position: relative;
    right: 0;
    transition: 0.5s ease;
}

@media all and (max-width: 768px) {
    .app-slide-window {
        display: block;
    }
}