.product-main-slider {
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.product-slide-window {
    display: flex;
    justify-content: center;
}

.product-slide-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s ease;
}

.product-simple-slide {
    height: 596px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-slider-btns {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 116px;
    justify-content: space-between;
    border-radius: 2px;
}

.product-slider-btns button {
    width: 53px;
    height: 58px;
    border-radius: 2px;
}

.app-main-products-slider-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.app-main-products-slider-arrows span {
    font-size: 13px;
    position: absolute;
    display: block;
    line-height: 15px;
}

.app-main-products-slider-arrows {
    position: absolute;
}

.leftTop img {
    width: 49px;
    height: 151px;
}

.leftBottom {
    top: 30%;
    right: 100%;
}

/*.p41 .leftBottom {*/
/*    left: 3%;*/
/*}*/

/*.p52 .leftBottom {*/
/*    left: 3%;*/
/*}*/

.leftBottom span{
    bottom: 20%;
    left: 5%;
    width: 210px;
}

.leftBottom img{
    bottom: 20%;
    left: 10%;
    width: 220px;
}

.leftUp span{
    left: 5%;
    top: 15%;
}

.leftTop span{
    top: 10%;
    left: 20%;
    width: 140px;
}

.bottomLeft {
    bottom: -12%;
    left: 30%;
}

.bottomLeft span {
    bottom: 10%;
    left: 5%;
    width: 270px;
}

.rightTop img {
    height: 120px;
    width: 49px;
    transform: rotateY(-180deg);
}

.rightTop span {
    top: -10%;
    left: -600%;
    width: 280px;
}

.leftUp {
    right: 100%;
    top: 70%;
}

.leftUp span{
    width: 210px;
}

.leftUp img {
    width: 210px;
}

.p12, .p13, .p14, .p21, .p22, .p23, .p24, .p31,
.p41, .p51, .p52, .p61, .p63, .p71, .p72, .p81,
.p82, .p83, .p84 {
    padding-left: 18%;
}

.p22, .p23, .p24, .p41 {
    margin-bottom: 100px;
}

/*first picture Emis-Doc */

.app-main-products-slider-wrapper>div {
    max-width: 68%;
    width: 100%;
    position: relative;
}

.app-main-products-slider-wrapper>div>img {
    max-width: 100%;
    width: 100%;
}

.p11-0 {
    top: -22%;
    left: 28%;
}

.p11-1 {
    top: -22%;
    left: 55%;
}

.p11-1 img {
    width: 44px;
    height: 136px;
}

/*second picture Emis-Doc */
.p12-1 {
    top: -90px;
    left: 50%;
}

.p12-1 img {
    width: 38px;
    height: 108px;
}

/*third picture Emis-Doc */

.p13-0 {
    top: 40%;
}

.p13-1 {
    top: -20%;
    left: 50%;
}

.p13 .p13-1 img {
    width: 44px;
    height: 90%;
}

/* CDS-Tenders */
.p21-0 {
    top: -20%;
    left: 17%;
}

.p21-1 {
    top: -20%;
    left: 80%;
}

.p21-1.rightTop span {
    left: -500%;
}

.p22-0 {
    top: 25%;
}

.p22-0.leftBottom, .p83 .leftBottom, .p84 .leftBottom, .p83 .leftUp {
    right: 95%
}

.p22 .p22-0 img {
    width: 250px;
}

.p22-0.leftBottom span {
    left: 5%;
}

.p22-1.bottomLeft {
    bottom: -18%;
    left: 40%;
}

.p23 .bottomLeft span {
    width: 310px;
}

.p23 .bottomLeft, .p24 .bottomLeft {
    bottom: -18%;
}

/* CDS-Fields */
.p31 {
    margin-top: 100px;
}

.p31-1 {
    top: -25%;
    left: -5%;
}

.p31-2 {
    top: -25%;
    right: 27%;
}

.p31-3 {
    top: -25%;
    right: -7%;
}

.p31-0.leftBottom {
    top: 20%;
}

.p31-1.rightTop span {
    top: -10%;
    left: -80%;
    width: 280px;
}
.p31-2.rightTop span {
    left: -50%;
    width: 200px;
}

.p31-3.rightTop span {
    left: -27%;
    width: 135px;
}

.p31 .rightTop img {
    height: 145px;
    width: 200px;
}

.p31>div {
    top: -25%;
}

/*Generator KP*/
.p41-1 {
    bottom: -20%;
    right: 15%;
}

.p41-1 img {
    height: 100px;
}

.p41-1 span {
    width: 330px;
    bottom: 10%;
    right: -30%;
}

/*ManagingCredit*/

.p61 .leftBottom span, .p61 .leftUp span {
    width: 170px;
}

.p61-0.leftBottom {
    top: 10%;
}

.p61-1.leftBottom {
    top: 28%;
}

.p63-1 {
    top: -13%;
    left: 17%;
}

.p63-1.leftTop img, .p63-2.rightTop img {
    height: 101px;
}

.p63-1.leftTop span {
    top: -10%;
    left: 100%;
    width: 130px;
}

.p63-2 {
    top: -13%;
    right: 6%;
}

.p63-2.rightTop span {
    left: -370%;
    width: 200px;
}

.p63-0.leftBottom span {
    bottom: 20%;
    left: 10%;
    width: 110px;
}


.p63-0.leftBottom {
    top: 18%;
}
/* TXC*/

.p71-0 {
    top: 18%;
}

.p71-1 {
    top: 31%;
}

.p71-2 {
    top: 44%;
}

.p71-3.leftUp {
    right: 86%
}

.p71-3.leftUp img {
    width: 310px;
}

.p71-4 {
    top: -30%;
    right: 20%;
}

.p71-4.leftTop span {
    width: 120px;
    top: -8%;
    left: 110%;
}

.leftUp {
    top: 81%;
}

/* Terminals */

.p81-1 {
    top: -14%;
    right: 15%;
}

.p81-0.leftBottom {
    top: 35%;
}

.p81-1.rightTop img {
    height: 160px;
}

.p81-1.rightTop span {
    top: 0%;
    left: -370%;
    width: 180px;
}

.p82-0.leftUp {
    top: 41%;
}

.p83-0.leftBottom {
    top: 30%;
}

.p83-0.leftBottom img, .p83-1.leftUp img, .p84-0.leftBottom img{
    width: 250px;
}

.p84-0.leftBottom {
    top: 30%;
}

/* MonSTR*/
.p91 span {
    position: relative;
}

.p91.app-main-products-slider-wrapper>div>img {
    width: 60%;
}

.p91 img {
    max-width: 100%;
    width: 45%;
    margin-left: 50%;
}

.p91-link {
    position: absolute;
    top: 40%;
    left: 10%;
}

.p91-link a{
    color: #4CACE9;
}

@media all and (max-width: 1500px) {
    .app-main-products-slider-wrapper > div {
        max-width: 500px;
    }
}

@media all and (max-width: 1400px) {
    .leftBottom {
        right: 90%;
    }

    .p71 .leftBottom, .p72 .leftBottom {
        right: 100%;
    }

    .leftBottom span {
        width: 160px;
    }

    .rightTop span {
        left: -400%;
        width: 180px;
    }

    .p83-0.leftBottom img, .p83-1.leftUp img, .p84-0.leftBottom img{
        width: 180px;
    }

    .p11-0, .p11-1 {
        top: -42%;
    }

    .p21-0, .p21-1 {
        top: -30%;
    }

    .p21-1.rightTop span {
        left: -250%;
    }

    .p22-0.leftBottom {
        right: 82%;
    }

    /* CDS-Fields */
    .p31-2.rightTop span {
        left: -10%;
        width: 120px;
    }

    .p31-3.rightTop span {
        left: -10%;
        width: 120px;
    }

    .p63-0.leftBottom span {
        bottom: 20%;
        left: 5%;
        width: 110px;
    }

    .p31-1.rightTop span {
        left: -50%;
        width: 180px;
    }

    .p31 {
        margin-top: 70px;
    }

    .p31-1 {
        left: 2%;
    }

    .p31-1, .p31-2, .p31-3 {
        top: -35%;
    }

    .p31>div {
        top: -40%;
    }

    .p41-1 {
        bottom: -25%;
    }

    .p63-1, .p63-2 {
        top: -30%;
    }

    .p63-2.rightTop span {
        left: -240%;
        width: 130px;
    }

    .p63-0.leftBottom {
        top: 20%;
    }

    .p71-3.leftUp img {
        width: 230px;
    }

    .p71-4 {
        top: -40%;
    }

    .p71-0 {
        top: 16%;
    }

    .p71-1 {
        top: 30%;
    }

    .p81-1.rightTop img {
        height: 120px;
    }

    .p82-0.leftUp span {
        width: 160px;
    }

    .leftBottom img {
        width: 160px;
    }

    .leftUp img {
        width: 160px;
    }

    .p63-2 {
        right: 10%;
    }

    .p84-0.leftBottom span {
        bottom: 20%;
        left: 5%;
        width: 160px;
    }
}

@media all and (max-width: 1200px) {
    .leftBottom {
        right: 100%;
    }

    .p22-0.leftBottom {
        right: 95%;
    }

    .p71-3.leftUp {
        right: 90%;
    }

    .p31-2.rightTop span {
        width: 100px;
        left: -10%;
    }

    .p31-1.rightTop span {
        top: -10%;
        left: -60%;
        width: 200px;
    }

    .p31-3.rightTop span {
        left: -5%;
        width: 100px;
    }

    .p31 {
        margin-top: 100px;
    }

    .p31-1 {
        left: 30%;
    }

    .p31-3 {
        right: 3%;
    }

    .p31>div {
        top: -30%;
    }

    .p71-0 {
        top: 20%;
    }

    .p71-1 {
        top: 32%;
    }

    .p71-2 {
        top: 46%;
    }

    .p81-1.rightTop img {
        height: 160px;
    }

    .p83 .leftBottom, .p84 .leftBottom, .p83 .leftUp {
        right: 100%;
    }

    .p82-0.leftUp img {
        width: 210px;
    }

    .leftUp img {
         width: 170px;
    }

    .p71-3.leftUp img {
        width: 210px;
    }
}

@media all and (max-width: 1024px) {
    .p12, .p13, .p14, .p21, .p22, .p23, .p24, .p31,
    .p41, .p51, .p52, .p61, .p63, .p71, .p72, .p81,
    .p82, .p83, .p84 {
        padding-left: 0%;
    }

    .p12>div>img {
        width: 246px !important;
        height: 136px !important;
    }

    .app-main-products-slider-wrapper > div {
        display: flex;
    }

    .p91.app-main-products-slider-wrapper>div>img {
        width: 100%;
        height: 206px;
        margin-left: 70%;
    }

    .p91-link {
        position: absolute;
        top: 30%;
        left: -70%;
    }

    .p91.app-main-products-slider-wrapper {
        padding-bottom: 0px;
    }

    .app-main-products-slider-wrapper>div>img{
        width: 88%;
        margin: 0 auto;
    }

    .p31.app-main-products-slider-wrapper>div>img {
        width: 100%;
    }

    .p41.app-main-products-slider-wrapper>div>img {
        width: 100%;
    }

    .p21.app-main-products-slider-wrapper>div>img,
    .p22.app-main-products-slider-wrapper>div>img,
    .p23.app-main-products-slider-wrapper>div>img {
        width: 100%;
    }

    .p22, .p23, .p24, .p41 {
         margin-bottom: 0px;
    }

    .app-main-products-slider-arrows span {
        font-size: 12px;
        line-height: 14px;
    }

    .rightBottom span, .p23-1.leftBottom span {
        position: absolute;
        bottom: 40%;
        left: 10px;
        width: 130px;
    }

    .p11-0.rightBottom span {
        left: 50px;
    }

    .p21 .rightBottom span, .p22 .rightBottom span,
    .p23 .rightBottom span, .p61 .rightBottom span,
    .p63 .rightBottom span, .p71 .rightBottom span,
    .p81 .rightBottom span, .p83 .rightBottom span{
        left: 20px;
    }

    .p84-0.leftBottom span {
        bottom: 20px;
    }

    .p71-0.leftBottom span, .p72-0.leftBottom span,
    .p81-0.leftBottom span, .p82-0.leftBottom span,
    .p83-0.leftBottom span, .p84-0.leftBottom span {
        width: 140px;
    }

    .leftBottom span {
        bottom: 20px;
        left: 10px;
        width: 120px;
    }

    .app-main-products-slider-wrapper>img {
        width: 100%;
    }

    .app-main-products-slider-wrapper img {
        height: 186px;
    }

    .leftBottom img, .rightBottom img, .p22 .rightBottom img {
        width: 160px;
    }

    .product-slide-wrapper {
        top: 0;
        transform: translateY(0);
    }

    .product-slide-window {
        display: block;
    }

    /*first picture Emis-Doc*/
    .rightBottom {
        top: 20%;
        left: 90%;
    }

    .p21 .rightBottom, .p22-1.rightBottom, .p23-1.rightBottom,
    .p41-1.rightBottom {
        top: 20%;
        left: 95%;
    }

    .p31-2.rightBottom {
        left: 100%;
    }

    .p41-0.leftBottom {
        left: -40%;
    }

    .p41-1.rightBottom span {
        bottom: 85%;
        width: 160px;
    }

    .p51-0.leftBottom, .p52-0.leftBottom, .p61-0.leftBottom, .p63-0.leftBottom {
        left: -30%;
    }

    .p61 .leftBottom span {
        width: 130px;
    }

    .p63-0.leftBottom span {
        bottom: 40%;
    }

    .leftBottom {
        top: 20%;
        left: -32%;
    }

    .leftBottom img, .rightBottom img, .p22 .p22-0 img {
        width: 150px;
        height: 49px;
    }

    .rightBottom img {
        transform: rotateY(-180deg);
    }


    .p21-0, .p22-0, .p23-0, .p31-0 {
        top: 20%;
        left: -45%;
    }

    .p21-0.rightBottom span, .p22-0.rightBottom span, .p23-0.rightBottom span, .p24-0.rightBottom span{
        left: 5%;
        bottom: 20px;
    }

    .p21-1.leftBottom span {
        top: 24%;
        left: 15%;
    }

    .p22-1.leftBottom, .p23-1.leftBottom, .p24-1.leftBottom {
        left: 100%;
    }

    .p22-1.leftBottom span {
        bottom: 40%;
        left: 0%;
        width: 155px;
    }

    .p24-1 {
        top: 30%;
    }

    .p24-1.leftBottom span {
        bottom: 47%;
        left: 5%;
        width: 155px;
    }

    .hidden {
        display: none;
    }
}

@media all and (max-width: 768px) {
    .p91.app-main-products-slider-wrapper>div>img,
    .p21.app-main-products-slider-wrapper>div>img,
    .p22.app-main-products-slider-wrapper>div>img,
    .p23.app-main-products-slider-wrapper>div>img,
    .p31.app-main-products-slider-wrapper>div>img,
    .p41.app-main-products-slider-wrapper>div>img {
        max-width: 230px;
    }

    .p91.app-main-products-slider-wrapper>div {
        width: 240px;
        justify-content: flex-end;
    }

    .p91.app-main-products-slider-wrapper>div>img {
        margin: 0;
        width: auto;
    }

    .p31-2 {
        right: 20%;
    }

    .p31 .rightTop img {
        height: 99px;
        width: 44px;
    }

    .p91-link {
        left: 0px;
    }

    .p31-2.rightTop span {
        width: 180px;
        left: -300%;
        top: 10px;
    }

    .app-main-products-slider-wrapper {
        padding-bottom: 40px;
    }

    .p31.app-main-products-slider-wrapper {
        padding-bottom: 0px;
        margin-top: 70px;
    }

    .p41, .p51, .p52  {
        padding-bottom: 0px;
    }

    .p41-1 span {
        width: 180%;
    }

    .p52-0.leftTop {
        top: -65%;
        left: 35%;
    }

    .product-slide-window {
        height: 100%;
    }

    .product-slide-wrapper {
        height: 100%;
    }

    .product-simple-slide {
        height: calc(100% - 70px);
    }

    .leftTop {
        width: 140px;
        top: -50%;
        left: 35%;
    }

    .leftTop span {
        left: 5%;
        width: 180px;
    }

    .bottom {
        position: absolute;
        top: 45%;
    }

    .p22-1.bottom span {
        left: -330%;
        bottom: -5%;
        width: 165px;
    }

    .bottomLeftMob span{
        width: 180px;
        left: 80%;
        top: 60%;
    }

    .bottom span {
        left: -260%;
        bottom: -5%;
        width: 130px;
    }

    .app-main-products-slider-wrapper img {
        height: 136px;
        width: 44px;
    }

    .p12 .p12-1 img {
        height: 106px;
        transform: rotateY(0deg);
        width: 49px;
    }

    .p41 img .p11 .p11-1 img, .p61-0 img,
    .product-simple-slide .leftTop img,
    .product-simple-slide .bottomLeftMob img {
        height: 99px;
        width: 44px;
        transform: rotateY(0deg);
    }

    .p41-1.bottom {
        top: 85%;
    }

    .p11-1 {
        top: -55px;
        left: 45%;
    }

    .p11-1 span{
        width: 140px;
    }

    .p61-0.leftTop {
        left: 10%;
        top: -40%;
    }

    .p12-0, .p13-0, .p14-0 {
        top: -55px;
        left: 25%;
    }

    .p12-0 span, .p13-0 span, .p14-0 span {
        width: 160px;
    }

    .p21-0, .p22-0, .p41-0 {
        top: -55%;
        left: 15%;
    }

    .p23-0, .p24-0 {
        top: -50%;
        left: 15%;
    }

    .p21-0 span, .p22-0 span, .p41-1.bottom span {
        width: 180px;
    }

    .p41-1.bottom span {
        left: -300%;
        bottom: -70%;
    }

    .p23-0 span, .p24-0 span {
        width: 190px;
    }

    .p11 .p11-0 img, .p21 .p21-1 img,
    .p22 .p22-1 img, .p31 .p31-1 img {
        width: 49px;
        height: 136px;
        transform: rotateY(0deg);
    }

    .p11-0 {
        top: 25%;
        left: 62%;
    }

    .p12-1, .p21-1, .p22-1 {
        top: 50%;
        left: 68%;
    }

    .bottomLeftMob {
        top: 60%;
        left: 8%;
    }

    .p63-0.bottomLeftMob {
        left: 18%;
    }

    .p63-1.leftTop img, .p63-2.rightTop img,
    .p81-1.rightTop img, .p82-0.rightTop img {
        height: 61px;
    }

    .p82-0.app-main-products-slider-arrows,
    .p84-0.app-main-products-slider-arrows{
        top: -40%;
        left: 40%;
    }

    .p63-1, .p63-2 {
        top: -30%;
    }

    .p71-0.bottomLeftMob span {
        width: 210px;
        top: 60%;
    }

    .p71-4 {
        top: -70%;
    }

    .p72-0.leftTop {
        top: -75%;
    }

    .p71-4.rightTop span {
        top: 5%;
        left: -230%;
        width: 100px;
    }

    .p81-0.bottomLeftMob {
        left: 28%;
    }

    .p81-1 {
        top: -44%;
    }

    .p81-1.rightTop span {
        left: -200%;
        width: 140px;
    }

    .p82-0 span {
        left: -200%;
        width: 120px;
    }

    .p83-0.app-main-products-slider-arrows {
        top: -70%;
        left: 10%;
    }

    .p84-0.rightTop span {
        top: -25%;
        left: -100%;
        width: 210px;
    }
}