.app-main-services {
    background: url("../images/bgMain/bg3-1920.png") no-repeat;
    position: relative;
    margin-bottom: 35px;
    background-size: cover;
    border-radius: 10px;
}

.app-main-services .page-description {
    padding-top: 70px;
}

.app-main-services .page-header {
    padding-bottom: 22px;
}

.app-services-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 55px;
    max-width: 1560px;
}

.app-services-brick-wrapper {
    width: 427px;
    height: 387px;
    display: flex;
    position: relative;
}

.app-services-brick>* {
    z-index: 1;
}

.app-services-brick,
.app-services-brick.white:nth-child(odd)::after,
.app-services-brick.white:nth-child(odd)::before{
    width: 221px;
    height: 221px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.02em;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px 15px 25px 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.app-services-brick:nth-child(odd) {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.app-services-brick:nth-child(even) {
    position: absolute;
    right: 0px;
}

.app-services-brick.white:nth-child(odd)::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.app-services-brick.white:nth-child(odd)::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(210deg, rgba(255, 255, 255) 10%, rgba(255, 255, 255, 0.5));
    backdrop-filter: blur(4px);
}

.app-services-brick.white:nth-child(odd)::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    top: -75%;
    right: -93.7%;
    z-index: 0;
    background: linear-gradient(70deg, rgba(255, 255, 255) 10%, rgba(255, 255, 255, 0.5));
    backdrop-filter: blur(4px);
}

.app-services-brick-description {
    height: calc(100% - 50px);
    display: flex;
    justify-content: flex-end;
    font-size: 15px;
    font-weight: 500;
    flex-direction: column;
}

.blue {
    background: rgba(76, 172, 233);
}

.blue .app-services-brick-description p {
    color: rgba(255, 255, 255, 1);
}

.app-services-brick:nth-child(even)::before,
.app-services-brick:nth-child(even)::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 5px solid white;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 1;
}

.app-services-brick.blue:nth-child(even)::before,
.app-services-brick.blue:nth-child(even)::after {
    border: 5px solid rgba(76, 172, 233);
}

.app-services-brick.white:nth-child(even)::before,
.app-services-brick.white:nth-child(even)::after {
    border: 5px solid rgba(255, 255, 255);
}

.app-services-brick:nth-child(even)::after {
    bottom: -15px;
    left: 10px;
    clip: rect(auto, 10px, 10px, auto);
    clip-path: rect(auto 10px 10px auto);
}

.app-services-brick:nth-child(even)::before {
    bottom: 50px;
    left: -14px;
    clip: rect(10px, auto, auto, 10px);
    clip-path: rect(10px auto auto 10px);
}

.app-services-brick-wrapper.s1 {
    order: 1;
    margin-left: 11%;
}

.app-services-brick-wrapper.s2 {
    order: 4;
}

.app-services-brick-wrapper.s3 {
    order: 2;
}

.app-services-brick-wrapper.s4 {
    order: 5;
}

.app-services-brick-wrapper.s5 {
    order: 3;
}

.app-services-brick-wrapper.s6 {
    order: 6;
}

@media all and (max-width: 1380px) {
    .app-services-brick-wrapper.s1 {
        order: 1;
        margin-left: 0px;
    }

    .app-services-brick-wrapper.s2 {
        order: 3;
    }

    .app-services-brick-wrapper.s3 {
        order: 2;
    }

    .app-services-brick-wrapper.s4 {
        order: 4;
    }

    .app-services-brick-wrapper.s5 {
        order: 5;
    }

    .app-services-brick-wrapper.s6 {
        order: 6;
    }
}

@media all and (max-width: 1200px) {
    .app-main-services {
        background: url("../images/bgMain/bg3-mobile.png") no-repeat;
        background-size: cover;
        margin-bottom: 25px;
    }

    .app-main-services .page-description {
        padding-top: 0px;
    }
}

@media all and (max-width: 768px) {
    .app-services-wrapper {
        padding-top: 15px;
        padding-bottom: 30px;
    }

    .app-services-brick,
    .app-services-brick.white:nth-child(odd)::after,
    .app-services-brick.white:nth-child(odd)::before{
        width: 148px;
        height: 148px;
        padding: 0px 10px 10px 13px;
    }

    .app-services-brick img {
        height: 17px;
    }

    .app-services-brick-description {
        font-size: 13px;
    }

    .app-services-brick-wrapper {
        width: 286px;
        height: 259px;
    }

    .app-services-brick:nth-child(even)::before {
        bottom: 32px;
    }

    .app-services-brick:nth-child(even)::after {
        left: 5px;
    }

    .app-services-brick-wrapper.s1 {
        order: 1;
    }

    .app-services-brick-wrapper.s2 {
        order: 3;
    }

    .app-services-brick-wrapper.s3 {
        order: 4;
    }

    .app-services-brick-wrapper.s4 {
        order: 3;
    }

    .app-services-brick-wrapper.s5 {
        order: 5;
    }

    .app-services-brick-wrapper.s6 {
        order: 6;
    }
}