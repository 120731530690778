.app-main-header {
    background: url('../images/bgMain/img1-1920.png') center center no-repeat, url('../images/bgMain/bg1-1920.png');
    background-size: cover;
    padding: 218px 71px 115px 75px;
    color: white;
    display: flex;
    position: relative;
    border-radius: 10px;
    margin-bottom: 35px;
    background-repeat: no-repeat;
}

.app-main-header-content {
    max-width: 729px;
    width: 59%;
    background: transparent;
}

.app-main-header-content > *, .app-main-header-content-footer span {
    color: white;
}

.app-main-header-content h1 {
    font-size: 100px;
    line-height: 90px;
    font-weight: 500;
    letter-spacing: -5px;
}

.app-main-header-content h2 {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -3px;
    margin-top: 7px;
}

.app-main-header-btn-container {
    display: flex;
    font-weight: 500;
    height: 58px;
    max-width: 538px;
    justify-content: space-between;
    margin-top: 24px;
}

.app-main-header .blueBtn {
    width: 287px;
}

.app-main-header-btn {
    width: 236px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(255, 255, 255, 1);
    color: rgba(76, 172, 233, 1);
    border-radius: 2px;
}

.app-main-header-content-footer {
    margin-top: 159px;
}

@media all and (max-width: 1500px) {
    .app-main-header-content h2 {
        font-size: 50px;
    }
}
@media all and (max-width: 1400px) {
    .app-main-header-btn-container .blueBtn {
        display: none;
    }

    .app-main-header {
        padding: 118px 20px 60px 30px;
    }

    .app-main-header-btn {
        width: 293px;
    }

    .app-main-header-content-footer {
        margin-top: 75px;
        max-width: 70%;
    }

    .app-main-header-content h1 {
        font-size: 50px;
        line-height: 52px;
        letter-spacing: -0.06em;
        max-width: 300px;
    }

    .app-main-header-content h2 {
        font-size: 26px;
        line-height: 35px;
        letter-spacing: -0.06em;
        margin-top: 7px;
        max-width: 280px;
    }
}

@media all and (max-width: 1200px) {
    .app-main-header {
        background: url('../images/bgMain/img1-1200.png') 90% bottom no-repeat, url('../images/bgMain/bg1-1920.png');
        background-size: auto;
    }
}

@media all and (max-width: 900px) {
    .app-main-header {
        background: url('../images/bgMain/img1-1200.png') 50% bottom no-repeat, url('../images/bgMain/bg1-1920.png');
        background-size: auto;
    }
}

@media all and (max-width: 768px) {
    .main-slider {
        display: none;
    }

    .app-main-header-content {
        width: 100%;
    }

    .app-main-header-content h1 {
        font-size: 40px;
        line-height: 43px;
        letter-spacing: initial;
    }

    .app-main-header-content h2 {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: initial;
        margin-top: 0px;
    }

    .app-main-header {
        padding: 52px 13px 338px 13px;
        background-image: url('../images/bgMain/bg1-mobile.png');
        margin-bottom: 25px;
        margin-top: 34px;
    }

    .app-main-header-content-footer span {
        font-size: 13px;
    }

    .app-main-header-content-footer {
        margin-top: 15px;
        line-height: 15px;
        max-width: 100%;
    }

    .app-main-header-btn-container {
        margin-top: 19px;
    }

    .app-main-header-btn {
        width: 305px;
        margin: 0 auto;
    }

    .app-main-header {
        background-size: cover;
    }
}
